<script>
import feather from 'feather-icons';
import Button from './reusable/Button.vue';
import FormInput from './reusable/FormInput.vue';
import FormTextarea from './reusable/FormTextarea.vue';
import emailjs from 'emailjs-com';
import SuccessMessage from './reusable/SuccessMessage.vue';

export default {
  props: ['showModal', 'modal', 'categories'],
  components: { Button, FormInput, FormTextarea, SuccessMessage },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      showSuccessMessage: false,
      successMessage: ''
    };
  },
  mounted() {
    feather.replace();
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          'service_nxd9gze',
          'template_dc5d1ns',
          this.$refs.form,
          'wq2jo63ExLfecSoLr'
        )
        .then((result) => {
          console.log('SUCCESS!', result.text);
          this.showSuccessMessage = true; // Show success message
          this.successMessage = '';
          this.resetForm(); // Reset the form fields
          this.storeSuccessMessage(); // Store the success message
          setTimeout(() => {
            this.refreshPage();
          }, 9000);
          // this.refreshPage() // Refresh the page
        })
        .catch((error) => {
          console.log('FAILED...', error.text);
        });
    },
    resetForm() {
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';
    },
    storeSuccessMessage() {
      // Store success message in localStorage
      localStorage.setItem('successMessage', '');
    },
    refreshPage() {
      location.reload();
    },
    dismissSuccessMessage() {
      this.showSuccessMessage = false;
    }
  }
}
</script>

<template>
  <transition name="fade">
    <div v-show="modal" class="font-general-regular fixed inset-0 z-30">
      <!-- Modal body background as backdrop -->
      <div v-show="modal" @click="showModal()" class="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"></div>
      <!-- Modal content -->
      <main class="flex flex-col items-center justify-center h-full w-full">
        <transition name="fade-up-down">
          <div v-show="modal" class="modal-wrapper flex items-center z-30">
            <div class="modal max-w-md mx-5 md:max-w-xl bg-secondary-light dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative">
              <div class="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light dark:border-ternary-dark">
                <h5 class="text-primary-dark dark:text-primary-light text-xl">
                  {{ $t('heierme_what_kind_of_project') }}
                </h5>
                <button class="px-4 text-primary-dark dark:text-primary-light" @click="showModal()">
                  <i data-feather="x"></i>
                </button>
              </div>
              <div class="modal-body p-5 w-full h-full">
                <SuccessMessage v-if="showSuccessMessage" :message="$t('email_sent_success')" @dismiss="dismissSuccessMessage" />
                <form ref="form" @submit.prevent="sendEmail" class="max-w-xl m-4 text-left">
                  <FormInput :label="$t('full_name')" inputIdentifier="from_name" v-model="name" />
                  <FormInput label="Email" inputIdentifier="user_email" inputType="email" v-model="email" />
                  <FormInput :label="$t('subject')" inputIdentifier="user_subject" inputType="subject" v-model="subject" />
                  <FormTextarea :label="$t('message')" textareaIdentifier="message" v-model="message" />

                  <div class="mt-7 pb-4 sm:pb-1">
                    <Button
                      :title="$t('send')"
                      class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-indigo-500 dark:bg-pink-500 hover:bg-indigo-600 dark:hover:bg-pink-600 rounded-md focus:ring-1 focus:ring-indigo-900 duration-500"
                      type="submit"
                      aria-label="Submit Request"
                    />
                  </div>
                </form>
              </div>
              
            </div>
          </div>
        </transition>
      </main>
    </div>
  </transition>
</template>

<style scoped>
.modal-body {
  max-height: 570px;
}
.bg-gray-800-opacity {
  background-color: #2d374850;
}
@media screen and (max-width: 768px) {
  .modal-body {
    max-height: 400px;
  }
}
.fade-up-down-enter-active {
  transition: all 0.3s ease;
}
</style>